<template>
  <el-table v-loading="loading" :data="boards">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Tên" prop="name" min-width="200">
      <template slot-scope="scope">
        <a
          :href="`/boards/${scope.row.id}/detail`"
          class="text-limited"
          target="_blank"
        >{{ getTranslation(scope.row, language) ? getTranslation(scope.row, language).name : 'Unknown' }}</a>
      </template>
    </el-table-column>
    <el-table-column label="Mô tả" prop="description" min-width="200">
      <template slot-scope="scope">
        <span class="text-limited">{{ getTranslation(scope.row, language) ? getTranslation(scope.row, language).description : 'Unknown' }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngày tạo" prop="created_at" min-width="100" align="center">
    </el-table-column>
  </el-table>
</template>

<script>
import { getTranslation } from '@/utils/filters'

export default {
  name: 'ListBoard',
  props: {
    boards: Array,
    loading: Boolean,
    language: String
  },
  data() {
    return {
      data: []
    }
  },
  methods: {
    getTranslation
  }
}
</script>

<style lang="scss" scoped>
a {
  color: #1174d4;
}
</style>
